import { $axios } from "@/utils/axios";

export default {
  namespaced: true,

  state: {
    order: {},
    customer: {},
    selectACustomer: false,
    device: {},
    selectADevice: false,
    technician: {},
    selectATechnician: false,
    operator: {},
    selectAOperator: false,
  },

  getters: {
    order: (state) => state.order,
    customer: (state) => state.customer,
    selectACustomer: (state) => state.selectACustomer,
    device: (state) => state.device,
    selectADevice: (state) => state.selectADevice,
    technician: (state) => state.technician,
    selectATechnician: (state) => state.selectATechnician,
    operator: (state) => state.operator,
    selectAOperator: (state) => state.selectAOperator,
  },

  mutations: {
    setOrder(state, order) {
      state.order = order;
    },
    setCustomer(state, customer) {
      state.customer = customer;
    },
    setSelectACustomer(state, payload) {
      state.selectACustomer = payload;
    },
    setDevice(state, device) {
      state.device = device;
    },
    setSelectADevice(state, payload) {
      state.selectADevice = payload;
    },
    setTechnician(state, technician) {
      state.technician = technician;
    },
    setSelectATechnician(state, payload) {
      state.selectATechnician = payload;
    },
    setOperator(state, operator) {
      state.operator = operator;
    },
    setSelectAOperator(state, payload) {
      state.selectAOperator = payload;
    },
  },

  actions: {
    warrantyStatus: ({ dispatch, rootState }) => {
      dispatch("setLoading", true, { root: true });

      const params = [`access_token=${rootState.auth.token}`].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/warranty_status?${params}`)
          .then((resp) => {
            resolve(resp.data.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    getOrders: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      function convertDate(date) {
        var yyyy = date.getFullYear().toString();
        var mm = (date.getMonth() + 1).toString();
        var dd = date.getDate().toString();

        var mmChars = mm.split("");
        var ddChars = dd.split("");

        return (
          yyyy +
          "-" +
          (mmChars[1] ? mm : "0" + mmChars[0]) +
          "-" +
          (ddChars[1] ? dd : "0" + ddChars[0])
        );
      }

      let repared_at_from = payload.repared_at_from
        ? convertDate(payload.repared_at_from)
        : "";
      let repared_at_to = payload.repared_at_to
        ? convertDate(payload.repared_at_to)
        : "";

      const params = [
        `access_token=${rootState.auth.token}`,
        `page=${payload.page}`,
        `take=${payload.perPage}`,
        `sort_field=${payload.sortField}`,
        `sort_order=${payload.sortOrder}`,
        `search=${payload.searchQuery}`,
        `search_deleted=${payload.searchDeleted}`,
        `repared_at_from=${repared_at_from}`,
        `repared_at_to=${repared_at_to}`,
        `technical_id=${payload.technical_id}`,
        `status_id=${payload.status_id}`,
        `is_fv=${payload.filters.is_fv}`,
        `is_paid=${payload.filters.is_paid}`,
        `is_warranty=${payload.filters.is_warranty}`,
        `is_np=${payload.filters.is_np}`,
        `unfinished=${payload.filters.unfinished}`,
      ];
      if (payload.hide_status_ids) {
        params.push(
          `hide_status_ids=${JSON.stringify(payload.hide_status_ids)}`
        );
      }
      return new Promise((resolve, reject) => {
        $axios
          .get(`/order?${params.join("&")}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    getOrder: ({ commit, dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [`access_token=${rootState.auth.token}`].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/order/${payload.id}?${params}`)
          .then((resp) => {
            commit("setOrder", resp.data);
            dispatch("setCustomer", {
              id: resp.data.customer_id,
              name: resp.data.customer_name,
              address: resp.data.customer_address,
              nip: resp.data.customer_nip,
              phone: resp.data.customer_phone,
            });
            dispatch("setDevice", {
              id: resp.data.device_id,
              serial: resp.data.device_serial,
              type: resp.data.device_type,
              name: resp.data.device_name,
            });
            dispatch("setTechnician", {
              id: resp.data.technical_id,
              firstname: resp.data.technical_name.split(" ")[0],
              lastname: resp.data.technical_name.split(" ")[1],
            });
            dispatch("setOperator", {
              id: resp.data.operator_id,
              firstname: resp.data.operator_name.split(" ")[0],
              lastname: resp.data.operator_name.split(" ")[1],
            });
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    postOrder: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      const status_id = payload.order.status_id ? payload.order.status_id : 30;
      const id = payload.id;
      const method = id ? "put" : "post";
      const url = id ? `/order/${id}` : "/order";

      return new Promise((resolve, reject) => {
        $axios({
          method,
          url,
          data: {
            ...payload.order,
            status_id,
            access_token: rootState.auth.token,
          },
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => {
            dispatch("setLoading", false, { root: true });
            dispatch("setSelectACustomer", false);
            dispatch("setSelectATechnician", false);
            dispatch("setSelectAOperator", false);
            dispatch("setSelectADevice", false);
          });
      });
    },
    removeOrder: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      $axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${rootState.auth.token}`;

      return new Promise((resolve, reject) => {
        $axios
          .delete(`/order/${payload.id}?access_token=${rootState.auth.token}`)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    changeOrderStatus: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      const id = payload.id;
      const status_id = payload.status_id;
      const method = "put";
      const url = `/order/${id}`;

      return new Promise((resolve, reject) => {
        $axios({
          method,
          url,
          data: {
            ...payload.order,
            status_id,
            access_token: rootState.auth.token,
          },
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => {
            dispatch("setLoading", false, { root: true });
            dispatch("setSelectACustomer", false);
            dispatch("setSelectATechnician", false);
            dispatch("setSelectADevice", false);
          });
      });
    },
    serviceType: ({ dispatch, rootState }) => {
      dispatch("setLoading", true, { root: true });

      const params = [`access_token=${rootState.auth.token}`].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/service_type?${params}`)
          .then((resp) => {
            resolve(resp.data.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    getCommuteItems: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [
        `access_token=${rootState.auth.token}`,
        `order_id=${payload.id}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/order_commute?${params}`)
          .then((resp) => {
            resolve(resp.data.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    editCommuteItems: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const method = "put";
      const url = `/order_commute/${payload.id}`;
      const item = {
        ...payload,
        commuted_at: new Date(payload.commuted_at),
      };
      return new Promise((resolve, reject) => {
        $axios({
          method,
          url,
          data: { ...item, access_token: rootState.auth.token },
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => {
            dispatch("setLoading", false, { root: true });
          });
      });
    },
    postCommuteItems: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const method = "post";
      const url = "/order_commute";

      return new Promise((resolve, reject) => {
        $axios({
          method,
          url,
          data: { ...payload.item, access_token: rootState.auth.token },
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => {
            dispatch("setLoading", false, { root: true });
          });
      });
    },
    removeCommuteItems: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      $axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${rootState.auth.token}`;

      return new Promise((resolve, reject) => {
        $axios
          .delete(
            `/order_commute/${payload.id}?access_token=${rootState.auth.token}`
          )
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    getWorkItems: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [
        `access_token=${rootState.auth.token}`,
        `order_id=${payload.id}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/order_work?${params}`)
          .then((resp) => {
            resolve(resp.data.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    postWorkItems: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const method = "post";
      const url = "/order_work";

      return new Promise((resolve, reject) => {
        $axios({
          method,
          url,
          data: { ...payload.item, access_token: rootState.auth.token },
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => {
            dispatch("setLoading", false, { root: true });
          });
      });
    },
    editWorkItems: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const method = "put";
      const url = `/order_work/${payload.id}`;
      const item = {
        ...payload,
        date: new Date(payload.worked_at),
      };
      return new Promise((resolve, reject) => {
        $axios({
          method,
          url,
          data: { ...item, access_token: rootState.auth.token },
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => {
            dispatch("setLoading", false, { root: true });
          });
      });
    },
    removeWorkItems: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      $axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${rootState.auth.token}`;

      return new Promise((resolve, reject) => {
        $axios
          .delete(
            `/order_work/${payload.id}?access_token=${rootState.auth.token}`
          )
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    getOrderImage: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [
        `access_token=${rootState.auth.token}`,
        `order_id=${payload.id}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/order_image?${params}`)
          .then((resp) => {
            resolve(resp.data.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    postOrderImage: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      return new Promise((resolve, reject) => {
        $axios({
          method: "post",
          url: "/order_image",
          data: { ...payload, access_token: rootState.auth.token },
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => {
            dispatch("setLoading", false, { root: true });
          });
      });
    },
    removeOrderImage: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      $axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${rootState.auth.token}`;

      return new Promise((resolve, reject) => {
        $axios
          .delete(
            `/order_image/${payload.id}?access_token=${rootState.auth.token}`
          )
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    setSelectACustomer({ commit }, payload) {
      return new Promise((resolve) => {
        commit("setSelectACustomer", payload);
        resolve();
      });
    },
    setCustomer({ commit }, payload) {
      return new Promise((resolve) => {
        commit("setCustomer", payload);
        resolve();
      });
    },
    setSelectATechnician({ commit }, payload) {
      return new Promise((resolve) => {
        commit("setSelectATechnician", payload);
        resolve();
      });
    },
    setTechnician({ commit }, payload) {
      return new Promise((resolve) => {
        commit("setTechnician", payload);
        resolve();
      });
    },
    setSelectAOperator({ commit }, payload) {
      return new Promise((resolve) => {
        commit("setSelectAOperator", payload);
        resolve();
      });
    },
    setOperator({ commit }, payload) {
      return new Promise((resolve) => {
        commit("setOperator", payload);
        resolve();
      });
    },
    setSelectADevice({ commit }, payload) {
      return new Promise((resolve) => {
        commit("setSelectADevice", payload);
        resolve();
      });
    },
    setDevice({ commit }, payload) {
      return new Promise((resolve) => {
        commit("setDevice", payload);
        resolve();
      });
    },
    setOrder({ commit }, payload) {
      return new Promise((resolve) => {
        commit("setOrder", payload);
        resolve();
      });
    },
    getOrderTechnical: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [
        `access_token=${rootState.auth.token}`,
        `order_id=${payload.id}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/order_technical?${params}`)
          .then((resp) => {
            resolve(resp.data.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    postOrderTechnical: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const method = "post";
      const url = "/order_technical";

      return new Promise((resolve, reject) => {
        $axios({
          method,
          url,
          data: { ...payload.item, access_token: rootState.auth.token },
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => {
            dispatch("setLoading", false, { root: true });
          });
      });
    },
    editOrderTechnical: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const method = "put";
      const url = `/order_technical/${payload.id}`;

      return new Promise((resolve, reject) => {
        $axios({
          method,
          url,
          data: { ...payload, access_token: rootState.auth.token },
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => {
            dispatch("setLoading", false, { root: true });
          });
      });
    },
    removeOrderTechnical: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      $axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${rootState.auth.token}`;

      return new Promise((resolve, reject) => {
        $axios
          .delete(
            `/order_technical/${payload.id}?access_token=${rootState.auth.token}`
          )
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
  },
};
